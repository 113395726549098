<template>
  <div class="wrapper mt-5 mb-7 error-page">
    <row>
      <column
        xs="12"
        class="ff-flama fs-lg text-center">
        <h2 class="fs-lg fw-medium mb-4">{{ __('error401.title') }}</h2>
        <h1 class="fs-xxl fw-light tt-uppercase mb-5">{{ __('error401.info') }}</h1>
        <img
          :src="`${$cdn.resource(`gfx/${$store.state.publication.name}/401.png`)}`"
          alt="401"
          class="mb-7">
        <div v-html="__('error401.message')"></div>
      </column>
    </row>
  </div>
</template>

<script>
import { Page } from 'global-components';

export default {
  extends: Page,
};
</script>
